import React from "react"
import PageTransition from 'gatsby-plugin-page-transitions';
// import { Link } from "gatsby"
import Layout from "../../components/layout"
import WhoAreWe from "../../components/who-are-we"
import SEO from "../../components/seo"

class MediaLibrary extends React.Component {
  render = () => (
    
        <Layout>
            <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
            <div className="hero hero--smallest hero--project-media-library inuit-box inuit-box--flush">
                <h2 className="hero__message hero__message--bigger hero__message--center">
                
                </h2>
            </div>
            <article className="article project inuit-u-p0">
                <div className="article__body article__body--has-extra">
                    <br />
                    <h1 className="article__heading">MEDIA LIBRARY.</h1>
                    <p className="article__para">
                        Digitalisation du processus d'archivage des fichiers produits par les agences creatives
                    </p>
                </div>


                <p className="article__belt">
                    <img className="article__belt-image" src="/img/projects/media-library/Banner-Avantages.jpg" alt="mobile-apps" />
                </p>

                <div className="article__body">
                    <h2 className="article__heading">LE CHALLENGE</h2>
                    <p className="article__para">
                        Mise en place d’une solution robuste et facile d’utilisation permettant de structurer les fichiers de l’agence et faisant également office de point de collecte pour une sauvegarde quotidienne. Elle donne aussi aux administrateurs la possibilité de gérer le flux d’information, les accès et d’initier les projets. Aussi, la Media Librairy permet de capitaliser sur les créas produites et mettre a disposition des créatifs un outils pour recherche pour inspiration par tag, secteur d'activité, date, type de support, type de campagne, ... et enfin leurs donner possibilité de telechrger les fichiers ouverts relatives au projets et optimiser les temps de production.
                    </p>

                    <h2 className="article__heading">DISPOSITIF</h2>
                    <p className="article__para">
                        Les créatif interagissent via le reseau LAN avec un serveur physique installé chez l'agence, tous les uploads et downloads se font depuis le serveur local, ce qui rend la gestion des fichiers volumineux tres fluide.<br />
                        <br />
                        Un dispositif automatisant la compression des fichiers et leurs uploads aux serveur distant et facilemet parametrable depuis l'interface d'administration pour planifier cette action tard le soir et ne pas penaliser les autres utilisateurs pendant les heures de travails.
                        <br />
                        <br />
                        Les deux serveurs Local et distant sont accessible et permettent la navigation entres campagne, projet et fichier tout en permettant la visualisation, teléchargement ou upload aux profils ayant le privilèges necessaire pour ca.
                    </p>
                </div>
                
                <p className="article__belt inuit-u-mb0">
                    <img className="article__belt-image" src="/img/projects/media-library/Banner-architecture.jpg" alt="branding" />
                </p>
                
                <br /><br />
            </article>
            <WhoAreWe />
        </Layout>
    
  )

}

export default MediaLibrary
